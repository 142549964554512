import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/Layout';

import '../../assets/styles/pages/privacy-poilicy.scss';

const GraciasPage = () => {
  return (
    <Layout
      title="¡Gracias por descargar nuestro folleto! | ELE Barcelona"
      description="¡Muchas gracias por tu solicitud!"
    >
      <div className="privacy-policy privacy-policy--extended">
        <section className="first-section content">
          <div className="container">
            <div className="wrapper">
              <h1 className="c-title-42" style={{ textAlign: 'left', marginBottom: 40 }}>
                ¡Gracias por descargar nuestro folleto!
              </h1>
              <div className="section">
                {/* <p className="c-title-20 mb-3">¡Muchas gracias por tu solicitud!</p> */}
                <p className="c-text-16">
                  Tu descarga empezará en unos segundos. Si no lo recibes, haz click aquí para
                  conseguir tu copia.
                </p>
                <p className="c-title-16 mt-3">El equipo ELE Barcelona</p>
                <Link to="/" className="c-btn c-btn--red mt-50">
                  Volver a la página de inicio
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default GraciasPage;
